.main-layout {
	.sidebar-left::-webkit-scrollbar {
		display: none;
	}
	.sidebar-left {
		height: 100vh;
		position: fixed;
		left: 0;
		z-index: 999;
		overflow-y: auto;
		overflow-x: hidden;
		-ms-overflow-style: none; // Internet Explorer 10+
		scrollbar-width: none; // Firefox
		// box-shadow: 6px 0px 16px rgba(112, 112, 112, 0.1);
	}
	& .ubox-logo {
		margin: 26px  0 0 26px;
	}

	.header {
		background: #fff;
		position: fixed;
		z-index: 99;
		left: 50px;
		right: 0;
		align-items: center;
		padding-right: 12px;
		.header-right-content {
			.bell-icon {
				padding: 9.5px;
				box-sizing: border-box;
				margin-top: 2px;
				color: #3340b6;
				font-size: 14px;
				cursor: pointer;
				position: relative;
				margin-right: 22px;
			}
			.is-show-notice {
				background-color: #ecedf8;
				color: #3340b6;
				border-radius: 4px;
				padding: 9.5px;
			}
			.notice-badge {
				background-color: #ff4559;
				border-radius: 100%;
				font-size: 12px;
				line-height: 20px;
				font-family: 'Roboto', sans-serif;
				color: #fff;
				border: 2px solid #ffffff;
				// right: -7px;
				// top: 5px;
				padding: 2px 6px;

				cursor: pointer;
				&-notice-2num {
					border-radius: 10px;
					right: -10px;
				}
				&-notice-99 {
					font-size: 8px;
					right: -14px;
				}
			}

			.noti-count {
				margin-right: 12px;
				& > i {
					color: #3340b6;
				}
				& .ant-scroll-number {
					background-color: #ff4559;
					color: #fff;
					height: 20px;
					padding: 0px 6px;
					line-height: 20px;
					border-radius: 23px;
				}
			}
		}
	}
	.content {
		margin-top: 64px;
		padding: 16px 16px 16px 96px;
		background-color: #edf3f7;
	}
	.menu-group {
		width: 100%;
		align-items: center !important;
		display: flex !important;
	}
	.menu-group-collapse {
		width: 100%;
		align-items: center !important;
		display: flex !important;
		justify-content: center;
		line-height: 20px;
		height: 28px;
	}
	.ant-menu {
		border-inline-end: 0px !important ;
		margin-top: 30px;
		border-radius: 0 !important;
	}
	.ant-menu-item {
		color: #1a1a1a;
		margin: 0 !important;
		transition: none !important;
		a {
			color: #1a1a1a;
		}
		&-icon + span {
			color: #1a1a1a;
			margin-left: 0 !important;
		}
		&-icon {
			padding: 15px 15px;
		}
	}

	& .ant-menu-sub {
		&.ant-menu-inline {
			background-color: #fbd126 !important;
		}
	}
	.ant-menu-submenu {
		border-radius: 20px;
		padding: 0px !important;
		transition: none !important;
		&-active {
			.ant-menu-item-icon {
				background-color: #f7f7f7;
				border-radius: 12px;
			}
		}
		&-arrow {
			display: none;
		}
		&-title {
			display: grid;
			justify-items: center;
			align-content: center;
			transition: none !important;
			padding: 0 !important;
			height: 60px !important;
			line-height: 25px !important;
			margin-top: 25px !important;
		}
		&-title:hover {
			background-color: #fff !important;
		}

		&-selected {
			// color: #1a1a1a;
			.ant-menu-item-icon {
				background-color: #fcd535;
				border-radius: 12px;
			}
		}
		// &-active {
		// 	color: #1a1a1a;
		// }
	}

	& .ant-menu-sub {
		margin-top: 30px;
		.ant-menu-title-content {
			.wrapper-icon {
				position: absolute;
				top: 0;
				right: 8px;
				left: 10px;
			}
		}
	}
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
	background-color: #fcd535;
	cursor: pointer;
	color: #1a1a1a;
	border-radius: 20px;
	// margin: 0 10px;
}
.ant-menu-item-active {
	// margin: 0 10px;
	background-color: #f7f7f7;
	cursor: pointer;
	color: #1a1a1a;
	border-radius: 20px;
}

.ant-menu-vertical.ant-menu-sub {
	max-height: 100vh;
}

.ant-menu-vertical .ant-menu-item::after {
	transition: none;
}

.box-description {
	height: auto;
	position: fixed;
	bottom: 70px;
	right: 0;
	padding: 12px;
	z-index: 999;
}

.box-shadow-desc {
	position: relative;
	box-shadow: 0px 2px 8px rgba(17, 17, 17, 0.15);
	background: #fff3e4;
	border: 1px solid #fbd126;
	border-radius: 6px;
	padding: 12px;
	width: 304px;
	&-only {
		background: white;
		border-color: transparent;
	}
	& .fa-xmark {
		position: absolute;
		top: 12px;
		right: 12px;
		color: #b1b1b1;
	}
}

.box-shadow-descriptions {
	box-shadow: 0px 2px 8px 2px rgba(17, 17, 17, 0.15);
	padding: 16px;
	background: white;
	width: 304px;
}

.icons8-shopee {
	align-self: center;
	display: inline-block;
	width: 18px;
	height: 18px;
	background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiN1bmRlZmluZWQ7Ij48cGF0aCBmaWxsPSIjZjQ1MTFlIiBkPSJNMzYuNjgzLDQzSDExLjMxN2MtMi4xMzYsMC0zLjg5Ni0xLjY3OS0zLjk5Ni0zLjgxM2wtMS4yNzItMjcuMTRDNi4wMjIsMTEuNDc3LDYuNDc3LDExLDcuMDQ4LDExIGgzMy45MDRjMC41NzEsMCwxLjAyNiwwLjQ3NywwLjk5OSwxLjA0N2wtMS4yNzIsMjcuMTRDNDAuNTc5LDQxLjMyMSwzOC44MTksNDMsMzYuNjgzLDQzeiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNmNDUxMWUiIGQ9Ik0zMi41LDExLjVoLTJDMzAuNSw3LjM2NCwyNy41ODQsNCwyNCw0cy02LjUsMy4zNjQtNi41LDcuNWgtMkMxNS41LDYuMjYyLDE5LjMxMywyLDI0LDIgUzMyLjUsNi4yNjIsMzIuNSwxMS41eiI+PC9wYXRoPjxwYXRoIGZpbGw9IiNmYWZhZmEiIGQ9Ik0yNC4yNDgsMjUuNjg4Yy0yLjc0MS0xLjAwMi00LjQwNS0xLjc0My00LjQwNS0zLjU3N2MwLTEuODUxLDEuNzc2LTMuMTk1LDQuMjI0LTMuMTk1IGMxLjY4NSwwLDMuMTU5LDAuNjYsMy44ODgsMS4wNTJjMC4xMjQsMC4wNjcsMC40NzQsMC4yNzcsMC42NzIsMC40MWwwLjEzLDAuMDg3bDAuOTU4LTEuNTU4bC0wLjE1Ny0wLjEwMyBjLTAuNzcyLTAuNTIxLTIuODU0LTEuNzMzLTUuNDktMS43MzNjLTMuNDU5LDAtNi4wNjcsMi4xNjYtNi4wNjcsNS4wMzljMCwzLjI1NywyLjk4Myw0LjM0Nyw1LjYxNSw1LjMwOSBjMy4wNywxLjEyMiw0LjkzNCwxLjk3NSw0LjkzNCw0LjM0OWMwLDEuODI4LTIuMDY3LDMuMzE0LTQuNjA5LDMuMzE0Yy0yLjg2NCwwLTUuMzI2LTIuMTA1LTUuMzQ5LTIuMTI1bC0wLjEyOC0wLjExOGwtMS4wNDYsMS41NDIgbDAuMTA2LDAuMDg3YzAuNzEyLDAuNTc3LDMuMjc2LDIuNDU4LDYuNDE2LDIuNDU4YzMuNjE5LDAsNi40NTQtMi4yNjYsNi40NTQtNS4xNThDMzAuMzkzLDI3LjkzMywyNy4xMjgsMjYuNzQxLDI0LjI0OCwyNS42ODh6Ij48L3BhdGg+PC9zdmc+')
		50% 50% no-repeat;
	background-size: 100%;
}
.items-group {
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	align-items: center;
	& .icon:not(:last-of-type) {
		margin-bottom: 12px;
	}
}

.ant-menu-submenu-title {
	margin: 0 8px;
}
.ant-menu-submenu-title:hover {
	border-radius: 20px;
}
.ant-menu-item {
	transition: none !important;
}

.ant-menu-submenu-popup {
	// margin-left: -15px !important;
	top: 0 !important;
	height: 100vh !important;
	position: fixed !important;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']) {
	height: 100vh;
	padding-top: 70px;
	width: 250px;
	padding-left: 5px;
	padding-right: 5px;
}

.icon-dark-mode {
	color: #707070;
	padding: 10px;
	border: 1px solid #707070;
	border-radius: 12px;
}

.ant-menu-item a:hover {
	color: #1a1a1a;
}

.ant-menu-item-selected a {
	color: #1a1a1a;
}
.ant-menu-vertical .ant-menu-item::after {
	transform: none;
}

.ant-menu-submenu-expand-icon,
.ant-menu-submenu-arrow {
	display: none;
}
.ant-menu-submenu-popup {
	.ant-menu-vertical.ant-menu-sub {
		padding-left: 10px;
		padding-right: 10px;
		max-height: 100% !important;
		margin-left: -5px;
	}
	.ant-menu-vertical {
		box-shadow: 0 6px 16px 0 rgb(0 0 0 / 10%), 0 3px 6px -4px rgb(0 0 0 / 5%), 0 9px 28px 8px rgb(0 0 0 / 0%);
	}
	.ant-menu-vertical > .ant-menu-item {
		margin-bottom: 10px;
		display: flex;
	}
	.ant-menu-vertical > .ant-menu-submenu > .ant-menu-submenu-title {
		transition: none;
	}
}

.ant-menu-item:not(.ant-menu-item-selected):hover {
	border-radius: 20px;
}
.ant-menu-submenu-title:active {
	color: #f7f7f7;
}

