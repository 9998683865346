.shipment {
	&-heading {
		display: flex;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid #ebebeb;
		box-shadow: 0px 2px 14px #e6e6e6;
		border-radius: 6px;
		background-color: white;
		padding: 12px;
		margin-bottom: 12px;
		&__left {
			display: flex;
			& .info {
				display: flex;
				margin-right: 80px;
				& .ant-avatar {
					margin-right: 4px;
					border-radius: 4px;
				}

				&-text {
					display: flex;
					flex-direction: column;
					justify-content: flex-start;
					&__code-status {
						display: flex;
						flex-direction: column-reverse;
						align-items: flex-start;
						& .status-shipment {
							border-radius: 25px;
							color: white;
							padding: 2px 12px;
							text-transform: capitalize;
							margin-right: 4px;
						}
						& .code-shipment {
							font-size: 14px;
							color: #1a1a1a;
						}
					}

					&-connection {
						display: flex;
						align-items: center;
						color: #1a1a1a;
						& .ant-divider {
							border-left: 1px solid #1a1a1a;
							margin: 0 4px;
						}
					}

					&__code {
						color: #1a1a1a;
						font-size: 14px;
						line-height: 22px;
					}
				}
			}

			& .finance {
				display: flex;
				flex-direction: column;
				margin-right: 80px;
				justify-content: space-between;
				&-item {
					display: flex;
					align-items: center;
					&__label {
						color: #707070;
						margin-right: 4px;
					}

					&__value {
						font-weight: 700;
						font-size: 14px;
						line-height: 22px;
						color: #1a1a1a;
					}
				}
			}

			& .merchant {
				display: flex;
				& .ant-avatar {
					margin-right: 4px;
				}
				&-label {
					color: #707070;
					margin-right: 4px;
				}
				&-info {
					&__name {
						color: #1a1a1a;
					}
				}
			}
		}

		&-left {
			& .ant-btn {
				font-size: 14px;
			}
		}
	}

	&-details {
		display: flex;
		&__text {
			flex-basis: calc((100% - 12px) * 8 / 10);
			margin-right: 12px;

			&-top {
				display: flex;
				background-color: white;
				margin-bottom: 12px;
				padding: 12px;
				border-radius: 4px;

				position: relative;
				& .basic,
				& .finance {
					flex-basis: 50%;
				}

				& .basic {
					display: flex;
					flex-direction: column;
					margin-bottom: 24px;
					margin-right: 12px;
					&-collapsed {
						margin-bottom: 0;
					}
					&-title {
						color: #1a1a1a;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
						margin-bottom: 5px;
					}
					&-item {
						&:not(:last-of-type) {
							margin-bottom: 6px;
						}
					}

					&-item,
					& .item {
						display: flex;
						&__label {
							font-size: 12px;
							line-height: 20px;
							color: #5a5a5a;
							white-space: nowrap;
							margin-right: 2px;
						}
						&__content {
							font-size: 14px;
							color: #1a1a1a;
							flex-basis: 80px;
							flex-grow: 1;
						}
					}

					&-number-data {
						display: flex;
						flex-wrap: wrap;
						& .item {
							display: flex;
							align-items: baseline;
							flex-basis: calc((100% - 24px) / 2);
							font-size: 14px;
							line-height: 22px;
							flex-shrink: 0;
							margin-right: 10px;
							& .ant-typography-ellipsis {
								& > span:first-of-type {
									white-space: nowrap;
								}
							}
							&-services {
								flex-basis: 100%;
								align-items: baseline;
								& .item__label {
									line-height: 22px;
								}
								& .service {
									font-size: 14px;
									white-space: nowrap;
								}
							}

							& .service-divider {
								margin: 0 4px;
							}
							&:last-of-type {
								margin-bottom: 0;
							}
						}
					}

					&-note,
					& .address-warehouse {
						display: flex;
						flex-direction: column;
						& .item {
							display: flex;
							align-items: baseline;
							&__label {
								font-size: 12px;
								line-height: 20pxx;
								color: #5a5a5a;
								white-space: nowrap;
								margin-right: 3px;
							}

							& .info-item {
								position: relative;
								& .fa-copy,
								& .fa-check {
									position: absolute;
									bottom: 5px;
									cursor: pointer;
								}
							}
						}
					}

					& .ant-divider {
						margin: 6px 0;
					}

					&-collapsed {
						& .basic-title {
							margin-bottom: 0;
						}
					}
				}
				& .finance {
					padding-bottom: 24px;
					display: flex;
					flex-direction: column;
					&-title {
						color: #1a1a1a;
						font-weight: 500;
						font-size: 16px;
						line-height: 24px;
					}
					&-details-fees,
					& .summary {
						border-radius: 4px;
						padding: 8px 10px 8px 6px;
						margin-top: 8px;
						margin-bottom: 8px;
						background-color: #fff7ee;
						& .item {
							display: flex;
							align-items: center;
							justify-content: space-between;
							font-size: 14px;
							line-height: 22px;
							margin-bottom: 4px;
							& .label,
							& .value {
								font-size: 14px;
								line-height: 22px;
								color: #1a1a1a;
							}

							& .label {
								&-notice {
									font-size: 10px;
									line-height: 12px;
									color: #707070;
								}
							}

							& .value {
								&-by-marketplace {
									font-size: 10px;
									line-height: 12px;
									color: #707070;
								}
							}
						}

						& .fee-items {
							display: flex;
							justify-content: space-between;
							padding: 0 8px;
							padding-right: 0;
							color: #1a1a1a;
							font-size: 14px;
							line-height: 22px;
							& .label {
								font-size: 12px;
								line-height: 20px;
								color: #1a1a1a;
								&-notice {
									font-size: 10px;
									line-height: 12px;
									color: #707070;
								}
							}
						}

						& .ant-divider {
							margin: 6px 0;
							border-top: 1px solid #ffd7a5;
						}
					}
					& .summary {
						background-color: #fff6e5;
						border-radius: 4px;
						padding: 8px 10px 8px 6px;
					}

					&-collapse {
						padding: 0;
					}
				}
			}
		}
		&__milestone {
			background-color: white;
			box-shadow: 0 2px 14px #e6e6e6;
			border-radius: 4px;
			padding: 12px;
			flex-basis: calc((100% - 12px) * 2 / 10);
			height: fit-content;
			& .heading {
				color: #1a1a1a;
				font-weight: 500;
				font-size: 16px;
				line-height: 24px;
			}
		}

		&-tabs {
			background-color: white;
			border-radius: 4px;
			& .ant-tabs-nav-wrap {
				padding: 0 12px;
			}
		}
	}

	&-collapse {
		position: absolute;
		bottom: 12px;
		right: 12px;
		cursor: pointer;
		color: #1a1a1a;
		font-size: 12px;
	}

	&-confirm-delete {
		// & .ant-modal-close {
		// 	right: -10px;
		// }
		& .ant-modal-confirm-body {
			& .ant-modal-confirm-content {
				font-size: 14px;
				line-height: 22px;
			}
			& .ant-modal-confirm-title {
				font-size: 16px;
				line-height: 24px;
			}
		}
		& .ant-modal-confirm-btns {
			display: flex;
			justify-content: flex-end;
		}
	}
	&-product-form,
	&-delete-product {
		& .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
			order: 1;
		}

		& .ant-modal-content {
			& .ant-form-item-label > label {
				font-size: 14px;
				line-height: 22px;
				color: #1a1a1a;
			}

			& .ant-input,
			& .ant-input-number {
				border: 1px solid #c0c0c0;
				border-radius: 4px;
				color: #1a1a1a;
				padding: 6px 12px 6px 10px;
				font-size: 14px;
			}

			& .ant-input-number {
				display: flex;
				align-items: center;
				& .ant-input-number-input {
					padding: 0;
				}
			}

			& .ant-modal-body {
				& .ant-modal-confirm-title {
					font-weight: 500;
					font-size: 16px;
					line-height: 24px;
					color: #1a1a1a;
				}

				& .ant-form-item.image {
					height: 112px;
				}
			}
		}
	}

	&-modal-create-waybill {
		& .ant-modal-content {
			& .ant-modal-title {
				font-size: 16px;
				line-height: 24px;
			}
		}
	}

	&-bottom-element {
		// position: fixed;
		// bottom: 0;
		// left: 0;
	}
}

.create-waybills {
	&-error,
	&-warning {
		border-radius: 4px;
		padding: 4px 5px;
		display: flex;
		align-items: center;
	}
	&-error {
		background: #ffedef;
		border: 1px solid #ff4559;
		color: #ff4559;
	}

	&-warning {
		background: #fff9ea;
		border: 1px solid #fdb924;
		border-radius: 4px;
		color: #fdb924;
	}
}

